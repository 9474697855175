@import "../variables";
//
.left-nav-with-frames { // see the component: nav-frame
	box-sizing: content-box;
	display: block;
	margin: @marginTop-navFrames 0px 0px 0px;
	position: relative;
	z-index: 1;

	nav-vertical {
		float: left;
	}

	.nav-frames {
		display: block;
		//float: right;
		position: relative;
		text-align: left;
		//width: calc( 100% - @width-verticalNav - 40px ); // leaves 40px of space between the nav and the frames.

		z-index: 1;

		.nav-frame {
			// see: components/nav-frame  -- for the sections (frames) that are planted inside .nav-frames
		}
	}
}
