@import "./reset";
@import "./variables";
@import "./fonts";
@import "./animations";
@import "./fix/fix-layout";
@import "./common";
// component-overrides, page-layout, etc.
//@import "./layout";
@import "./components/nav-frames";
// Generic UI, or component things that are not inside component styles.
@import "./ui/buttons";
@import "./ui/inputs";
@import "./ui/typography";
// Modals
@import "./modals/modals";
// Global overrides - tramples over everything.
html, body {
	color: #252525;
	font-family: 'Roboto Condensed', sans-serif;
	//font-family: 'Lato', sans-serif;
  font-weight: 300;
}

.qrcode {
	canvas {
		aspect-ratio: 1;
		height: auto !important;
		max-width: 100%;
	}
}