@import "../variables";
// ===== Drop-Downs ===== //
//
// ===== Text Inputs ===== //
input[type="text"], input[type="password"], input[type="date"], input[type="time"], input[type="tel"], input[type="number"], .input {
	border: 1px solid #ccd2da;
	border-radius: 3px;
	box-sizing: border-box;
	color: #535f6b;
	font-family: @baseFonts;
	font-size: @fontSize-input;
	height: @height-input;
	line-height: @height-input;
	padding: 0px (@height-input / 4);
	transition: border 0.3s ease-in-out;
	width: 100%;
	-moz-appearance: textfield;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		margin: 0;
		-moz-appearance: textfield;
		-webkit-appearance: none;
	}

	&:focus {
		border-color: #525f6b;
	}

	&[readonly], &[disabled] {
		color: #ccd2da;
		// TODO: Lock or other mark.
	}

	&.error, &.ng-invalid.ng-touched {
		border-color: #FF8080;
		box-shadow: 0 0 5px #fbc4c0;
	}
}
