@import "../variables";
//
.button {
	//background: none no-repeat scroll 0px 0px / cover @colorActionPrimaryBackground;
	//border: 1px solid @colorActionPrimaryForeground;
	border-radius: @borderRadius-button @borderRadius-button @borderRadius-button @borderRadius-button;
	box-sizing: border-box;
	//color: @colorActionPrimaryForeground;
	cursor: pointer;
	display: inline-block;
	font-size: @fontSize-button;
	font-weight: @fontWeight-button;
	height: @button-totalHeight;
	line-height: @button-totalHeight;
	outline: 0px none transparent;
	padding: 0px @paddingInline-button 0px @paddingInline-button;
	text-align: center;
	text-decoration: none;
	white-space: nowrap;

	&:hover {
		//background-color: @colorActionPrimaryBackgroundHighlight;
		//border-color: @colorActionPrimaryForegroundHighLight;
		//color: @colorActionPrimaryForegroundHighLight;
	}

	&.orange { // these are examples, create your own.
		//background: none no-repeat scroll 0px 0px / cover @colorOrange;
		color: @backgroundColor-button-custom;

		&:active, &:focus, &:link, &:visited { // in-case .button is put onto an anchor tag.
			color: @backgroundColor-button-custom;
			outline: none;
			text-decoration: none;
		}
	}

	&.primary { // background/foreground are switched so the background is the colorful one.
		//background-color: @colorActionPrimaryForeground;
		//border-color: @colorActionPrimaryBackground;
		//color: @colorActionPrimaryBackground;

		&:hover {
			//background-color: @colorActionPrimaryForegroundHighLight;
			//border-color: @colorActionPrimaryBackgroundHighlight;
			//color: @colorActionPrimaryBackgroundHighlight;
		}
	}

	&.danger {
		//background-color: @colorActionDangerBackground;
		//border-color: @colorActionDangerForeground;
		//color: @colorActionDangerForeground;

		&:hover {
			//background-color: @colorActionDangerBackgroundHighlight;
			//border-color: @colorActionDangerForegroundHighlight;
			//color: @colorActionDangerForegroundHighlight;
		}
	}
}

.ui-button {
	// requires that you set your own margin, padding, height, line-height and width.
	//background: none no-repeat scroll 0px 0px / cover @colorActionPrimaryBackground;
	//border: 1px solid @colorActionPrimaryBackgroundHighlight;
	border-radius: 2px 2px 2px 2px;
	box-shadow: 0 2px 0 rgba( 0, 0, 0, 0.015 );
	box-sizing: border-box;
	//color: @colorGrey;
	cursor: pointer;
	display: inline-block;
	height: auto; // set your own
	margin: 0px 0px 0px 0px; // set your own
	outline: 0px none transparent;
	padding: 0px 0px 0px 0px; // set your own
	position: relative;
	text-align: center;
	text-decoration: none;
	touch-action: manipulation;
	transition: all 300ms @ani-fn-fade;
	user-select: none;
	white-space: nowrap;
	width: auto; // set your own

	a {
		color: inherit;
		height: 100%;
		outline: 0px none transparent;
		text-decoration: none;
		width: 100%;
	}

	&:hover {
		//background-color: @colorActionPrimaryBackground;
		//border-color: lighten( @colorActionPrimaryForeground, 15% ); // #40A9FF;
		//color: lighten( @colorActionPrimaryForeground, 15% );
	}

	&.primary {
		//background-color: @colorActionPrimaryForeground;
		//border-color: @colorActionPrimaryForeground;
		//color: @colorActionPrimaryBackground;

		&:hover {
			//background-color: @colorActionPrimaryForegroundHighLight;
			//border-color: @colorActionPrimaryForegroundHighLight;
			//color: @colorActionPrimaryBackground;

			a {
				//background-color: @colorActionPrimaryForegroundHighLight;
				//color: @colorActionPrimaryBackground;
			}
		}

		&.disabled {
			cursor: default;
			pointer-events: none;

			&:hover { // no hover effects, basically
				//background-color: @colorActionPrimaryForeground;
				//border-color: @colorActionPrimaryForeground;
				//color: @colorActionPrimaryBackground;
			}
		}
	}

	&.danger {
		//background-color: @colorActionDangerBackground;
		//border-color: @colorActionDangerForeground;
		//color: @colorActionDangerForeground;

		&:hover {
			//background-color: @colorActionDangerBackground;
			//color: @colorActionDangerForegroundHighlight;
			//border-color: @colorActionDangerForegroundHighlight;

			a {
				//background-color: @colorActionDangerBackground;
				//color: @colorActionDangerForegroundHighlight;
			}
		}
	}

	&.circle {
		border-radius: 50% 50% 50% 50%;
	}

	&.round {
		border-radius: 5000px 5000px 5000px 5000px; // just needs to be larger than the height, and all values needs to be equal.
	}
}
